import React from 'react';
export const Stats = () =>{
  return (
    <div className="banner  o-hidden success-number">
        <div className="clearfix"></div>
        <div className="parallax-container">
            <div className="clearfix"></div>
            <section>
                <div className="clearfix"></div>
                <div className="stuff" data-type="content">
                    <div className="container">
                        {/* <!--section title --> */}
                        <h2>Success In Numbers</h2>
                        {/* <!--end section title --> */}
                        <div className="row counter-inner">
                            {/* <!-- features box --> */}
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="icon-holder pull-left"><span className="icon-users2"></span></div>
                                <div className="pull-left counter-text">
                                    <div className="counter no_count b-clor">8</div>
                                    <p className="semi-bold">Happy Clients</p>
                                </div>
                            </div>
                            {/* <!--end features box --> */}
                            {/* <!-- features box --> */}
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="icon-holder pull-left"><span className="icon-calendar-check"></span></div>
                                <div className="pull-left counter-text">
                                    <div className="counter no_count b-clor">14</div>
                                    <p className="semi-bold">projects completed</p>
                                </div>
                            </div>
                            {/* <!--end features box --> */}
                            {/* <!-- features box --> */}
                            <div className="col-lg-4 col-md-4 col-sm-4">
                                <div className="icon-holder pull-left"><span className="icon-clock"></span></div>
                                <div className="pull-left counter-text">
                                    <div className="counter no_count b-clor">5</div>
                                    <p className="semi-bold">projects inprogress</p>
                                </div>
                            </div>
                            {/* <!--end features box --> */}
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
            </section>
            <div className="clearfix"></div>
        </div>
    </div>
  )
}