import React from "react";
import { Link } from "react-router-dom";
const NewsLetterForm = () => {
  return (
    // {/* <!--newsletter section--> */}
    <div className="grey-dark-bg text-center">
      <div className="container">
        <h2>Sign up for our newsletter to stay up to date with tech news!</h2>
        <div className="customise-form">
          <form>
            <div className="row">
              {/* <!--box one--> */}
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div className="form-group customised-formgroup">
                  {" "}
                  <span className="icon-user"></span>
                  <input className="form-control" placeholder="Name" />
                </div>
              </div>
              {/* <!--end box one--> */}
              {/* <!--box two--> */}
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div className="form-group customised-formgroup">
                  {" "}
                  <span className="icon-envelope"></span>
                  <input className="form-control" placeholder="Email" />
                </div>
              </div>
              {/* <!--end box two--> */}
              {/* <!--box three--> */}
              <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div>
                  <input
                    className="btn btn-fill full-width"
                    type="submit"
                    value="SIGN UP FOR FREE!"
                  />
                </div>
              </div>
              {/* <!--end box three--> */}
            </div>
          </form>
          <p className="light-gray-font-color">
            Lorem ipsum dolor sit amet, consectetuer adipi scing elit, sed diam
            nonummy nibh euismod tincidunt ut laoreet.
          </p>
        </div>
      </div>
    </div>
    // {/* <!--end newsletter section--> */}
  );
};
export const Footer = () => {
  return (
    <footer id="footer" className="text-left">
      {/* <!--scroll to top--> */}
      <Link className="top-btn page-scroll" to="#top">
        <span className="icon-chevron-up b-clor regular-text text-center"></span>
      </Link>
      {/* <!--end scroll to top--> */}
      {/* <NewsLetterForm /> */}
      {/* <!--footer content--> */}
      <div className="light-ash-bg">
        <div className="container">
          <ul>
            <li>
              {/* <!-- footer left content--> */}
              <ul>
                <li>
                  <Link to="/">
                    <img
                      src="/images/small-logo.png"
                      alt="logo"
                      className="img-responsive logo"
                    />
                  </Link>
                </li>
                <li>
                  <p className="extra-small-text">
                    &copy; {new Date().getFullYear()}
                  </p>
                </li>
                <li>
                  <p className="extra-small-text">
                    Struct Technologies Pvt Ltd.
                  </p>
                </li>
              </ul>
              {/* <!--end footer left content--> */}
            </li>
            <li>
              {/* <!--footer service list--> */}
              <ul>
                <li>
                  <a className="regular-text text-color-light">Services</a>
                </li>
                {/* <li>
                  <Link
                    to="services/logo-and-branding"
                    className="extra-small-text"
                  >
                    Logo &amp; Branding
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="services/web-development"
                    className="extra-small-text"
                  >
                    Website Development
                  </Link>
                </li>
                <li>
                  <Link
                    to="services/mobile-app-development"
                    className="extra-small-text"
                  >
                    Mobile App Development
                  </Link>
                </li>
                <li>
                  <Link to="services/seo" className="extra-small-text">
                    Search Engine Optimization
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="services/pay-per-click"
                    className="extra-small-text"
                  >
                    Pay-Per-Click
                  </Link>
                </li> */}
                {/* <li>
                  <Link
                    to="services/social-media-marketing"
                    className="extra-small-text"
                  >
                    Social Media Marketing
                  </Link>
                </li> */}
              </ul>
              {/* <!--end footer service list--> */}
            </li>
            <li>
              {/* <!--footer Resources list--> */}
              <ul>
                <li>
                  <a className="regular-text text-color-light">Resources</a>
                </li>
                <li>
                  <Link to="careers" className="extra-small-text">
                    Careers
                  </Link>
                </li>
                <li>
                  <Link to="portfolio" className="extra-small-text">
                    Portfolio
                  </Link>
                </li>
                {/* <li><Link to="case-studies" className="extra-small-text">Case Studies</Link></li> */}
                {/* <li><Link to="blog" className="extra-small-text">Blog</Link></li> */}
              </ul>
              {/* <!--end footer Resources list--> */}
            </li>
            <li>
              {/* <!--footer Support list--> */}
              <ul>
                <li>
                  <a className="regular-text text-color-light">Support</a>
                </li>
                <li>
                  <Link to="team" className="extra-small-text">
                    Our Team
                  </Link>
                </li>
                <li>
                  <Link to="contact" className="extra-small-text">
                    Contact
                  </Link>
                </li>
                <li>
                  <Link to="privacy-policy" className="extra-small-text">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="terms-conditions" className="extra-small-text">
                    Terms & Conditions
                  </Link>
                </li>
              </ul>
              {/* <!--end footer Support list--> */}
            </li>
            <li className="big-width">
              {/* <!--footer social media list--> */}
              <ul className="list-inline">
                <li>
                  <p className="regular-text text-color-light">Get in Touch</p>
                  <ul className="social-links">
                    <li>
                      <Link to="/">
                        <span className="icon-facebook"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <span className="icon-twitter"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <span className="icon-google-plus"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <span className="icon-instagram"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <span className="icon-pinterest"></span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/">
                        <span className="icon-linkedin"></span>
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
              {/* <!--end footer social media list--> */}
            </li>
          </ul>
        </div>
      </div>
      {/* <!--end footer content--> */}
    </footer>
  );
};
