import React from 'react';
export const Slider = () =>{
  return (
    <div className="main-slider">
        <div className="content">
            <div id="rev_slider_1059_1_wrapper" className="rev_slider_wrapper fullscreen-container" data-alias="concept121" data-source="gallery">
                {/* <!-- START REVOLUTION SLIDER 5.4.1 fullwidth mode --> */}
                <div id="rev_slider_1078_1" className="rev_slider fullscreenbanner" style={{"display":"none"}} data-version="5.4.1">
                    <ul>
                        {/* <!-- SLIDE  --> */}
                        <li data-index="rs-2972" data-transition="zoomin" data-slotamount="7" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed="2000" data-rotate="0" data-saveperformance="off" data-title="Love it?" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                            {/* <!-- MAIN IMAGE --> */}
                            <img src="/images/slider-1.jpg" alt="" data-bgposition="center center" data-kenburns="on" data-duration="10000" data-ease="Linear.easeNone" data-scalestart="100" data-scaleend="120" data-rotatestart="0" data-rotateend="0" data-offsetstart="0 -500" data-offsetend="0 500" data-bgparallax="10" className="rev-slidebg" data-no-retina />
                            {/* <!-- LAYERS --> */}
                            {/* <!-- Mobile only text --> */}
                            <div className="visible-xs caption-mobile">
                                <div className="h1" style={{"color":" #008ccb"}}>Great Website Adds Great Vaule</div>
                                <div className="h2" style={{"color":" #323a45"}}>Offer full service digital media solution to your clients.</div>
                            </div>
                            {/* <!-- LAYER NR. 1 --> */}
                            <div className="tp-caption NotGeneric-Title  tp-resizeme rs-parallaxlevel-3" id="slide-3046-layer-5" data-x="left" data-hoffset="0" data-y="center" data-voffset="0" data-width="none" data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;","ease":"Power2.easeInOut"}]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[60]" data-fontsize="[90,56,40,30]" data-paddingleft="[0,50,50,50]" style={{"zIndex": "9","whiteSpace":"nowrap","textTransform":"left"}}>
                                <div className="h1" style={{"color":" #008ccb;"}}>Great Website
                                    <br /> Adds Great Value</div>
                            </div>
                            {/* <!-- LAYER NR. 2 --> */}
                            <div className="tp-caption NotGeneric-SubTitle tp-resizeme rs-parallaxlevel-2" id="slide-3046-layer-2" data-x="left" data-hoffset="0" data-y="center" data-voffset="140" data-width="none" data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;","ease":"Power2.easeInOut"}]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{"zIndex": "9","whiteSpace": "nowrap","textTransform":"left"}}>
                                <div className="h2" style={{"color":" #323a45"}}>Offer full service digital media solution to your clients.</div>
                                <br />
                                <a href="index.html#" className="medium-btn btn btn-fill" data-bs-toggle="modal" data-bs-target="#getAQuoteModal">GET A QUOTE <span className="icon-chevron-right"></span></a> <a className="medium-btn2 btn btn-nofill page-scroll" href="index.html#portfolio-home">our portfolio</a> </div>
                        </li>
                        {/* <!-- SLIDE   --> */}
                        <li data-index="rs-3049" data-transition="zoomin" data-slotamount="7" data-hideafterloop="0" data-hideslideonmobile="off" data-easein="Power4.easeInOut" data-easeout="Power4.easeInOut" data-masterspeed="2000" data-rotate="0" data-saveperformance="off" data-title="Love it?" data-param1="" data-param2="" data-param3="" data-param4="" data-param5="" data-param6="" data-param7="" data-param8="" data-param9="" data-param10="" data-description="">
                            {/* <!-- MAIN IMAGE --> */}
                            <img src="/images/slider-2.jpg" alt="" data-bgposition="center center" data-kenburns="on" data-duration="10000" data-ease="Linear.easeNone" data-scalestart="100" data-scaleend="120" data-rotatestart="0" data-rotateend="0" data-offsetstart="0 -500" data-offsetend="0 500" data-bgparallax="10" className="rev-slidebg" data-no-retina />
                            {/* <!-- LAYERS --> */}
                            {/* <!-- Mobile only text --> */}
                            <div className="visible-xs caption-mobile">
                                <div className="h1" style={{"color":" #008ccb"}}>Great Website Adds Great Vaule</div>
                                <div className="h2" style={{"color":" #323a45"}}>Offer full service digital media solution to your clients.</div>
                            </div>
                            {/* <!-- LAYER NR. 1 --> */}
                            <div className="tp-caption NotGeneric-Title  tp-resizeme rs-parallaxlevel-3" id="slide-3046-layer-7" data-x="left" data-hoffset="0" data-y="center" data-voffset="0" data-width="none" data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;","ease":"Power2.easeInOut"}]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[60]" data-fontsize="[90,56,40,30]" data-paddingleft="[0,50,50,50]" style={{"zIndex":" 9"," whiteSpace": "nowrap","textTransform":"left"}}>
                                <div className="h1" style={{"color": "#008ccb"}}>Great Website
                                    <br /> Adds Great Values</div>
                            </div>
                            {/* <!-- LAYER NR. 2 --> */}
                            <div className="tp-caption NotGeneric-SubTitle tp-resizeme rs-parallaxlevel-2" id="slide-3046-layer-15" data-x="left" data-hoffset="0" data-y="center" data-voffset="140" data-width="none" data-height="none" data-whitespace="nowrap" data-type="text" data-responsive_offset="on" data-frames='[{"from":"y:[100%];z:0;rX:0deg;rY:0;rZ:0;sX:1;sY:1;skX:0;skY:0;opacity:0;","mask":"x:0px;y:[100%];","speed":2000,"to":"o:1;","delay":1500,"ease":"Power4.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;","ease":"Power2.easeInOut"}]' data-textAlign="['left','left','left','left']" data-paddingtop="[0,0,0,0]" data-paddingright="[0,0,0,0]" data-paddingbottom="[0,0,0,0]" data-paddingleft="[0,0,0,0]" style={{"zIndex": "9", "whiteSpace": "nowrap","textTransform":"left"}}>
                                <div className="h2" style={{"color":" #323a45"}}>Offer full service digital media solution to your clients.</div>
                                <br />
                                <a href="index.html#" className="medium-btn btn btn-fill" data-bs-toggle="modal" data-bs-target="#getAQuoteModal">GET A QUOTE <span className="icon-chevron-right"></span></a> <a className="medium-btn2 btn btn-nofill page-scroll" href="index.html#portfolio-home">our portfolio</a> </div>
                        </li>
                    </ul>
                    <div className="tp-bannertimer" style={{"height": "7px","background-color": "rgba(255, 255, 255, 0.25)"}}></div>
                </div>
            </div>
            {/* <!-- END REVOLUTION SLIDER --> */}
        </div>
    </div>
  )
}