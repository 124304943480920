import React from "react";

export const ServicesWebDevelopment = () => {
  return (
    <div>
      {/* <!-- ++++ banner ++++ --> */}
    <section className="banner  o-hidden banner-inner website-banner">
        <div className="container">
            {/* <!--banner text--> */}
            <div className="banner-txt">
                <h1>Website Design</h1>
                <p className="semi-bold">Great websites add great values to your business. From
                    <br /> wire-framing to PSD designing, we do it all.</p>
                <a href="website-development.html#process-tab" className="medium-btn3 btn btn-nofill page-scroll">OUR Process</a> </div>
            {/* <!--end banner text--> */}
        </div>
    </section>
    {/* <!-- ++++ end banner ++++ --> */}
    {/* <!-- ++++ responsive web design ++++ --> */}
    <section className="bg-white o-hidden common-form-section  service-description-section">
        <div className="container">
            {/* <!--section title --> */}
            <h2 className="b-clor">Responsive Web Design</h2>
            <hr className="dark-line" />
            {/* <!--end section title --> */}
            <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12">
                    <h3 className="semi-bold">One Design for All Platforms</h3>
                    <p className="regular-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. </p>
                    <ul>
                        <li><span className="icon-laptop-phone"></span>One design for your desktop, tab and mobile.</li>
                        <li><span className="icon-lollipop"></span>Beautiful and modern design that makes difference.</li>
                        <li><span className="icon-rocket"></span>Boost your sales with strategically built user experience.</li>
                    </ul>
                    <p className="regular-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna. Quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. </p>
                </div>
                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12"> </div>
                <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12">
                    <div className="customise-form">
                        <form className="email_form" method="post">
                            <div className="form-element-wrapper">
                                <h3>Get a Free Quote</h3>
                                <div className="form-group customised-formgroup">
                                    <span className="icon-user"></span>
                                    <input type="text" name="full_name" className="form-control" placeholder="Name"/>
                                </div>
                                <div className="form-group customised-formgroup">
                                    <span className="icon-envelope"></span>
                                    <input type="email" name="email" className="form-control" placeholder="Email" />
                                </div>
                                <div className="form-group customised-formgroup">
                                    <span className="icon-telephone"></span>
                                    <input type="text" name="phone" className="form-control" placeholder="Phone" />
                                </div>
                                <div className="form-group customised-formgroup">
                                    <span className="icon-laptop"></span>
                                    <input type="text" name="website" className="form-control" placeholder="Website" />
                                </div>
                                <div className="form-group customised-formgroup">
                                    <span className="icon-bubble"></span>
                                    <textarea name="message" className="form-control" placeholder="Message"></textarea>
                                </div>
                            </div>
                            <div>
                                <button type="submit" className="btn btn-fill full-width">GET A QUOTE<span className="icon-chevron-right"></span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- ++++ end responsive web design ++++ --> */}
    {/* <!-- ++++ design process section start ++++ --> */}
    <section className=" bg-white design-process-section" id="process-tab">
        <div className="container">
            {/* <!--section title --> */}
            <h2 className="b-clor text-align-center">Our Website Design Process</h2>
            <p className="regular-text text-align-center">Our comprehensive website design strategy ensures a perfectly crafted website for your business.</p>
            {/* <!--end section title --> */}
            <div className="row">
                <div className="col-sm-12">
                    {/* <!-- design process steps--> */}
                    {/* <!-- Nav tabs --> */}
                    <ul className="nav nav-tabs process-model" role="tablist">
                        <li role="presentation" className="active">
                            <a href="website-development.html#consultation" aria-controls="consultation" role="tab" data-bs-toggle="tab"> <span className="icon-bubble-user"></span>
                                <p>Consultation</p>
                            </a>
                        </li>
                        <li role="presentation"><a href="website-development.html#wire-frame" aria-controls="wire-frame" role="tab" data-bs-toggle="tab"><span className="icon-pencil-ruler"></span>
                                    <p>Wire-frame</p>
                                </a></li>
                        <li role="presentation"><a href="website-development.html#final-design" aria-controls="final-design" role="tab" data-bs-toggle="tab"><span className="icon-laptop-phone"></span>
                                    <p>Final Design</p>
                                </a></li>
                    </ul>
                    {/* <!-- end design process steps--> */}
                    {/* <!-- Tab panes --> */}
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active" id="consultation">
                            <div className="design-process-content">
                                <h3 className="semi-bold">Consultation</h3>
                                <p className="regular-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincid unt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullam corper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.</p>
                                <p className="regular-text">Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum.</p>
                                <img src="/images/design-process-img.jpg" alt="design process" className="img-responsive" /> </div>
                        </div>
                        <div role="tabpanel" className="tab-pane" id="wire-frame">
                            <div className="design-process-content">
                                <h3 className="semi-bold">Wire-frame</h3>
                                <p className="regular-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincid unt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullam corper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.</p>
                                <p className="regular-text">Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum.</p>
                                <img src="/images/seo-discovery.jpg" alt="design process" className="img-responsive" /> </div>
                        </div>
                        <div role="tabpanel" className="tab-pane" id="final-design">
                            <div className="design-process-content">
                                <h3 className="semi-bold">Final Design</h3>
                                <p className="regular-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincid unt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullam corper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.</p>
                                <p className="regular-text">Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum.</p>
                                <img src="/images/design-process-img.jpg" alt="design process" className="img-responsive" /> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- ++++ end design process section ++++ --> */}
    {/* <!-- ++++ featured design ++++ --> */}
    <section className="o-hidden bg-white featured-design-section mobile-app-featured">
        <div className="container">
            {/* <!--section title --> */}
            <h2 className="b-clor">Featured Designs</h2>
            <hr className="dark-line" />
            {/* <!--end section title --> */}
            <div className="row margin-top-40 logo-b portfolio">
                <div className="col-sm-6 col-md-4">
                    <div className="grid-item">
                        <div className="img_container">
                            <img src="/images/portfolio/portfolio-one.jpg" alt="port_img" className="img-responsive" />
                            <div className="overlay">
                                <a className="btn btn-nofill proDetModal1">Discover</a>
                            </div>
                            {/* <!-- End of .overlay --> */}
                        </div>
                        {/* <!-- End of .img_container --> */}
                        <div className="text-content">
                            <h3><a className="proDetModal proDetModal1">Diet Hospital<span>Website</span></a></h3>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4">
                    <div className="grid-item">
                        <div className="img_container">
                            <img src="/images/portfolio/portfolio-two.jpg" alt="port_img" className="img-responsive" />
                            <div className="overlay">
                                <a className="btn btn-nofill proDetModal2">Discover</a>
                            </div>
                            {/* <!-- End of .overlay --> */}
                        </div>
                        {/* <!-- End of .img_container --> */}
                        <div className="text-content">
                            <h3><a className="proDetModal proDetModal2">Jet Airplane<span>Website</span></a></h3>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4">
                    <div className="grid-item">
                        <div className="img_container">
                            <img src="/images/portfolio/portfolio-three.jpg" alt="port_img" className="img-responsive" />
                            <div className="overlay">
                                <a className="btn btn-nofill proDetModal3">Discover</a>
                            </div>
                            {/* <!-- End of .overlay --> */}
                        </div>
                        {/* <!-- End of .img_container --> */}
                        <div className="text-content">
                            <h3><a className="proDetModal proDetModal3">Spring Water Service<span>Website</span></a></h3>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4">
                    <div className="grid-item">
                        <div className="img_container">
                            <img src="/images/portfolio/portfolio-four.jpg" alt="port_img" className="img-responsive" />
                            <div className="overlay">
                                <a className="btn btn-nofill proDetModal4">Discover</a>
                            </div>
                            {/* <!-- End of .overlay --> */}
                        </div>
                        {/* <!-- End of .img_container --> */}
                        <div className="text-content">
                            <h3><a className="proDetModal proDetModal4">Second Language<span>Website</span></a></h3>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4">
                    <div className="grid-item">
                        <div className="img_container">
                            <img src="/images/portfolio/portfolio-five.jpg" alt="port_img" className="img-responsive" />
                            <div className="overlay">
                                <a className="btn btn-nofill proDetModal5">Discover</a>
                            </div>
                            {/* <!-- End of .overlay --> */}
                        </div>
                        {/* <!-- End of .img_container --> */}
                        <div className="text-content">
                            <h3><a className="proDetModal proDetModal5">Home Buy and Sell<span>Website</span></a></h3>
                        </div>
                    </div>
                </div>
                <div className="col-sm-6 col-md-4">
                    <div className="grid-item">
                        <div className="img_container">
                            <img src="/images/portfolio/portfolio-six.jpg" alt="port_img" className="img-responsive" />
                            <div className="overlay">
                                <a className="btn btn-nofill proDetModal6">Discover</a>
                            </div>
                            {/* <!-- End of .overlay --> */}
                        </div>
                        {/* <!-- End of .img_container --> */}
                        <div className="text-content">
                            <h3><a className="proDetModal proDetModal6">Technical University<span>Website</span></a></h3>
                        </div>
                    </div>
                </div>
                <div className="col-sm-12"> <a href="portfolio.html" className="btn btn-fill full-width">Discover more</a> </div>
            </div>
        </div>
    </section>
    {/* <!-- ++++ end featured design ++++ --> */}
    </div>
  );
};
