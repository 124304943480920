import React from 'react';
import { Link } from 'react-router-dom';
export const Services = () =>{
  return (
    <section className="bg-white o-hidden services" id="services">
        <div className="container">
            {/* <!--section title --> */}
            <h2 className="b-clor">Services We Provide</h2>
            <hr className="dark-line" />
            {/* <!--end section title --> */}
            <div className="row">
                {/* <!-- features box --> */}
                {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div className="box-green-border"> <Link to="services/logo-and-branding"><span className="icon-palette"></span>
                                <div>Logo &AMP; Branding</div>
                            </Link>
                        <p>Our comprehensive Online Marketing strategy will boost your website and traffic hence monthly sales.</p>
                    </div>
                </div> */}
                {/* <!--end features box --> */}
                {/* <!-- features box --> */}
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div className="box-green-border"> <Link to="services/web-development"><span className="icon-laptop-phone"></span>
                                <div>Website Development</div>
                            </Link>
                        <p>We design professional looking yet simple websites. Our designs are search engine and user friendly.</p>
                    </div>
                </div>
                {/* <!--end features box --> */}
                {/* <!-- features box --> */}
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div className="box-green-border"> <Link to="services/mobile-app-development"><span className="icon-smartphone-embed"></span>
                                <div>Mobile App Development</div>
                            </Link>
                        <p>From simple Content Management System to complex eCommerce developer, we cover it all.</p>
                    </div>
                </div>
                {/* <!--end features box --> */}
            {/* </div>
            <div className="row margin-top-15"> */}
                {/* <!-- features box --> */}
                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div className="box-green-border"> <Link to="services/seo"><span className="icon-magnifier"></span>
                                <div>Search Engine Optimization</div>
                            </Link>
                        <p>We design professional looking yet simple websites. Our designs are search engine and user friendly.</p>
                    </div>
                </div>
                {/* <!--end features box --> */}
                {/* <!-- features box --> */}
                {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div className="box-green-border"> <Link to="services/pay-per-click"><span className="icon-select2"></span>
                                <div>Pay-Per-Click</div>
                            </Link>
                        <p>Our comprehensive Online Marketing strategy will boost your website and traffic hence monthly sales.</p>
                    </div>
                </div> */}
                {/* <!--end features box --> */}
                {/* <!-- features box --> */}
                {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div className="box-green-border"> <Link to="services/social-media-marketing"><span className="icon-share"></span>
                                <div>Social Media Marketing</div>
                            </Link>
                        <p>Our comprehensive Online Marketing strategy will boost your website and traffic hence monthly sales.</p>
                    </div>
                </div> */}
                {/* <!--end features box --> */}
            </div>
        </div>
    </section>
  )
}