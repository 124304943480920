export const PortfolioItem = ({ img, name, type }) => {
  return (
    <div className="a2 a1 grid-item">
      <div className="img_container">
        <img src={`/images/portfolio/${img}`} alt="port_img" className="img-responsive" />
        <div className="overlay">
          <a className="btn btn-nofill proDetModal3">Discover</a>
        </div>
        {/* <!-- End of .overlay --> */}
      </div>
      {/* <!-- End of .img_container --> */}
      <div className="text-content">
        <h3>
          <a className="proDetModal proDetModal3">
            {name}
            <span>{type}</span>
          </a>
        </h3>
      </div>
    </div>
  );
};
