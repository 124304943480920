import React from 'react';

export const ServicesSEO = () => {
  return (<div>
{/* <!-- ++++ banner ++++ --> */}
    <section className="banner  o-hidden banner-inner seo-banner">
        <div className="container">
            {/* <!--banner text--> */}
            <div className="banner-txt">
                <h1>Search Engine Optimization</h1>
                <p className="semi-bold">Driving more traffic to your website by increasing rank in different
                    <br /> search engines.</p>
                <div><a href="seo.html#process-tab" className="medium-btn2 btn btn-nofill page-scroll">OUR Process</a></div>
            </div>
            {/* <!--end banner text--> */}
        </div>
    </section>
    {/* <!-- ++++ end banner ++++ --> */}
    {/* <!-- ++++ seo service description ++++ --> */}
    <section className="bg-white o-hidden common-form-section  service-description-section">
        <div className="container">
            {/* <!--section title --> */}
            <h2 className="b-clor">Be the Number One</h2>
            <hr className="dark-line" />
            {/* <!-- end section title --> */}
            <div className="row">
                <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12">
                    <h3 className="semi-bold">Stand Out in the Crowd!</h3>
                    <p className="regular-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. </p>
                    <ul>
                        <li><span className="icon-clipboard-pencil"></span>Get free SEO audit.</li>
                        <li><span className="icon-find-replace"></span>Thorough optimization to increase rank.</li>
                        <li><span className="icon-laptop-phone"></span>Keywords and landing page integration.</li>
                    </ul>
                    <p className="regular-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna. Quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. </p>
                </div>
                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12"> </div>
                <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12">
                    <div className="customise-form">
                        <form className="email_form" method="post">
                            <div className="form-element-wrapper">
                                <h3>Get a Free Quote</h3>
                                <div className="form-group customised-formgroup">
                                    <span className="icon-user"></span>
                                    <input type="text" name="full_name" className="form-control" placeholder="Name" />
                                </div>
                                <div className="form-group customised-formgroup">
                                    <span className="icon-envelope"></span>
                                    <input type="email" name="email" className="form-control" placeholder="Email" />
                                </div>
                                <div className="form-group customised-formgroup">
                                    <span className="icon-telephone"></span>
                                    <input type="text" name="phone" className="form-control" placeholder="Phone" />
                                </div>
                                <div className="form-group customised-formgroup">
                                    <span className="icon-laptop"></span>
                                    <input type="text" name="website" className="form-control" placeholder="Website" />
                                </div>
                                <div className="form-group customised-formgroup">
                                    <span className="icon-bubble"></span>
                                    <textarea name="message" className="form-control" placeholder="Message"></textarea>
                                </div>
                            </div>
                            <div>
                                <button type="submit" className="btn btn-fill full-width">GET A QUOTE<span className="icon-chevron-right"></span></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- ++++ end seo service description ++++ --> */}
    {/* <!-- ++++ design process section start ++++ --> */}
    <section className="bg-white design-process-section" id="process-tab">
        <div className="container">
            {/* <!--section title --> */}
            <h2 className="b-clor text-align-center">Our SEO Process</h2>
            <p className="regular-text text-align-center">Our comprehensive SEO strategy ensures increase in ranking in different search engines.</p>
            {/* <!--end section title --> */}
            <div className="row">
                <div className="col-sm-12">
                    {/* <!-- design process steps--> */}
                    {/* <!-- Nav tabs --> */}
                    <ul className="nav nav-tabs process-model more-icon-preocess" role="tablist">
                        <li role="presentation" className="active"><a href="seo.html#discover" aria-controls="discover" role="tab" data-bs-toggle="tab"><span className="icon-magnifier"></span>
                                    <p>Discover</p>
                                </a></li>
                        <li role="presentation"><a href="seo.html#strategy" aria-controls="strategy" role="tab" data-bs-toggle="tab"><span className="icon-archery"></span>
                                    <p>Strategy</p>
                                </a></li>
                        <li role="presentation"><a href="seo.html#optimization" aria-controls="optimization" role="tab" data-bs-toggle="tab"><span className="icon-puzzle"></span>
                                    <p>Optimization</p>
                                </a></li>
                        <li role="presentation"><a href="seo.html#content" aria-controls="content" role="tab" data-bs-toggle="tab"><span className="icon-papers"></span>
                                    <p>Content</p>
                                </a></li>
                        <li role="presentation"><a href="seo.html#reporting" aria-controls="reporting" role="tab" data-bs-toggle="tab"><span className="icon-pie-chart"></span>
                                    <p>Reporting</p>
                                </a></li>
                    </ul>
                    {/* <!-- end design process steps--> */}
                    {/* <!-- Tab panes --> */}
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active" id="discover">
                            <div className="design-process-content">
                                <h3 className="semi-bold">Discovery</h3>
                                <p className="regular-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincid unt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullam corper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.</p>
                                <p className="regular-text">Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. </p>
                                <img src="/images/design-process-img.jpg" alt="seo process" className="img-responsive" /> </div>
                        </div>
                        <div role="tabpanel" className="tab-pane" id="strategy">
                            <div className="design-process-content">
                                <h3 className="semi-bold">Strategy</h3>
                                <p className="regular-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincid unt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullam corper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.</p>
                                <p className="regular-text">Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum.</p>
                                <img src="/images/seo-discovery.jpg" alt="design process" className="img-responsive" /> </div>
                        </div>
                        <div role="tabpanel" className="tab-pane" id="optimization">
                            <div className="design-process-content">
                                <h3 className="semi-bold">Optimization</h3>
                                <p className="regular-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincid unt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullam corper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.</p>
                                <p className="regular-text">Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. </p>
                                <img src="/images/design-process-img.jpg" alt="seo process" className="img-responsive" /> </div>
                        </div>
                        <div role="tabpanel" className="tab-pane" id="content">
                            <div className="design-process-content">
                                <h3 className="semi-bold">Content</h3>
                                <p className="regular-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincid unt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullam corper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.</p>
                                <p className="regular-text">Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. </p>
                                <img src="/images/seo-discovery.jpg" alt="seo process" className="img-responsive" /> </div>
                        </div>
                        <div role="tabpanel" className="tab-pane" id="reporting">
                            <div className="design-process-content">
                                <h3 className="semi-bold">Reporting</h3>
                                <p className="regular-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincid unt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullam corper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat.</p>
                                <p className="regular-text">Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum. </p>
                                <img src="/images/design-process-img.jpg" alt="seo process" className="img-responsive" /> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- ++++ end design process section ++++ --> */}

  </div>);
};