import React from 'react';
export const Clients = () =>{
  return (
    <div className="clients">
        {/* <!-- clients item slider--> */}
        <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <div className="">
                        <div className="owl-carousel">
                            {/* <!--slider item--> */}
                            <div>
                                <a href="index.html#"><img src="/images/client1.png" alt="clients" className="img-responsive center-block" /></a>
                            </div>
                            {/* <!--end slider item--> */}
                            {/* <!--slider item--> */}
                            <div>
                                <a href="index.html#"><img src="/images/client2.png" alt="clients" className="img-responsive center-block" /></a>
                            </div>
                            {/* <!--end slider item--> */}
                            {/* <!--slider item--> */}
                            <div>
                                <a href="index.html#"><img src="/images/client3.png" alt="clients" className="img-responsive center-block" /></a>
                            </div>
                            {/* <!--end slider item--> */}
                            {/* <!--slider item--> */}
                            <div>
                                <a href="index.html#"><img src="/images/client4.png" alt="clients" className="img-responsive center-block" /></a>
                            </div>
                            {/* <!--end slider item--> */}
                            {/* <!--slider item--> */}
                            <div>
                                <a href="index.html#"><img src="/images/client5.png" alt="clients" className="img-responsive center-block" /></a>
                            </div>
                            {/* <!--end slider item--> */}
                            {/* <!--slider item--> */}
                            <div>
                                <a href="index.html#"><img src="/images/client6.png" alt="clients" className="img-responsive center-block" /></a>
                            </div>
                            {/* <!--end slider item--> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!--end clients item slider --> */}
    </div>
  )
}