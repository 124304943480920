import React from 'react';
import { email, mobile } from '../config';
export const Contact  = () =>{
  return (
    <>
      {/* <!-- ++++ banner ++++ --> */}
    <section className="banner  o-hidden banner-inner contact-banner">
        <div className="container">
            {/* <!--banner text--> */}
            <div className="banner-txt">
                <h1>Contact</h1>
                <p className="semi-bold">Get in touch with us to see how our company can help you grow
                    <br /> your business online.</p>
            </div>
            {/* <!--end banner text--> */}
        </div>
    </section>
    {/* <!-- ++++ end banner ++++ --> */}
    {/* <!-- ++++ contact form design ++++ --> */}
    <section className="bg-white o-hidden common-form-section contact-form-wrapper">
        <div className="container">
            {/* <!--section title --> */}
            <h2 className="b-clor">Send Us a Message</h2>
            <hr className="dark-line" />
            {/* <!--end section title --> */}
            <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12">
                    <div className="customise-form contact-form">
                        <form className="email_form" method="post">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group customised-formgroup"> <span className="icon-user"></span>
                                        <input type="text" name="full_name" className="form-control" placeholder="Name" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group customised-formgroup"> <span className="icon-envelope"></span>
                                        <input type="email" name="email" className="form-control" placeholder="Email" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group customised-formgroup"> <span className="icon-telephone"></span>
                                        <input type="text" name="phone" className="form-control" placeholder="Phone" />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group customised-formgroup"> <span className="icon-laptop"></span>
                                        <input type="text" name="website" className="form-control" placeholder="Website" />
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group customised-formgroup"> <span className="icon-bubble"></span>
                                        <textarea name="message" className="form-control" placeholder="Message"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-wrapper">
                                <button type="submit" className="btn btn-fill">Contact us now!</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                    <div className="contact-info-box-wrapper">
                        <div className="contact-info-box"> <span className="icon-telephone"></span>
                            <div>
                                <h6>Give us a call</h6>
                                <p>{mobile}</p>
                            </div>
                        </div>
                        <div className="contact-info-box"> <span className="icon-envelope"></span>
                            <div>
                                <h6>Send an email</h6>
                                <p>{email}</p>
                            </div>
                        </div>
                        <div className="contact-info-box">
                            <ul className="social-links">
                                <li><a href="contact.html#"><span className="icon-facebook"></span></a></li>
                                <li><a href="contact.html#"><span className="icon-twitter"></span></a></li>
                                <li><a href="contact.html#"><span className="icon-google-plus"></span></a></li>
                                <li><a href="contact.html#"><span className="icon-instagram"></span></a></li>
                                <li><a href="contact.html#"><span className="icon-pinterest"></span></a></li>
                                <li><a href="contact.html#"><span className="icon-linkedin"></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- end contact form design --> */}
    {/* <!-- google map location section start--> */}
    <section className="bg-white google-map-location">
        <div className="container">
            {/* <!--section title --> */}
            <h2 className="b-clor text-align-center">Visit Our Work Places</h2>
            {/* <!--end section title --> */}
            <div className="row">
                <div className="col-sm-12">
                    {/* <!-- design process steps--> */}
                    {/* <!-- Nav tabs --> */}
                    <ul className="nav nav-tabs process-model contact-us-tab" role="tablist">
                        <li role="presentation" className="active"><a href="contact.html#newYork" aria-controls="newYork" role="tab" data-bs-toggle="tab"><span className="icon-Asset-1"></span>
            <p>New York</p>
            </a></li>
                        <li role="presentation"><a href="contact.html#Dhaka" aria-controls="Dhaka" role="tab" data-bs-toggle="tab"><span className="icon-Asset-2"></span>
            <p>Dhaka</p>
            </a></li>
                        <li role="presentation"><a href="contact.html#delhi" aria-controls="delhi" role="tab" data-bs-toggle="tab"><span className="icon-Asset-3"></span>
            <p>Delhi</p>
            </a></li>
                    </ul>
                    {/* <!-- end design process steps--> */}
                    {/* <!-- Tab panes --> */}
                    <div className="tab-content">
                        <div role="tabpanel" className="tab-pane active" id="newYork">
                            <p className="regular-text">Theodore Lowe, Ap #867-859 Sit Rd, Azusa New York 39531</p>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193571.43843467633!2d-74.11808622462772!3d40.70582531304306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew+York%2C+NY%2C+USA!5e0!3m2!1sen!2sbd!4v1493743550190" height="500" style={{"border":"0","width":"100%"}} allowFullScreen></iframe>
                        </div>
                        <div role="tabpanel" className="tab-pane" id="Dhaka">
                            <p className="regular-text">Theodore Lowe, Ap #867-859 Sit Rd, Dhaka 39531</p>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3649.5981744200876!2d90.4145448154366!3d23.832883791536254!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c65de1c443d3%3A0x29e3642fb77053ae!2sRd+No.+13%2C+Dhaka!5e0!3m2!1sen!2sbd!4v1493743381942" height="500" style={{"border":"0","width":"100%"}} allowFullScreen></iframe>
                        </div>
                        <div role="tabpanel" className="tab-pane" id="delhi">
                            <p className="regular-text">Theodore Lowe, Ap #867-859 Sit Rd, Azusa delhi 39531</p>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112173.0889792281!2d77.13894525990527!3d28.52742286149734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x52c2b7494e204dce!2sNew+Delhi%2C+Delhi%2C+India!5e0!3m2!1sen!2sbd!4v1493743454675" height="500" style={{"border":"0","width":"100%"}} allowFullScreen></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* <!--end google map location section--> */}
    </>
  )
}