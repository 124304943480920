import React from 'react';

export const WhyUs = () => {
  return (
    <section className="bg-white o-hidden why-us common-form-section" id="why-us">
        <div className="container">
            {/* <!--section title --> */}
            <h2 className="b-clor">Reasons for Choosing Us</h2>
            <hr className="dark-line" />
            {/* <!--end section title --> */}
            <div className="row">
                {/* <!--text box--> */}
                <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12 text-left">
                    <h3 className="semi-bold">We Lead from the Front</h3>
                    <p className="regular-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. </p>
                    <ul>
                        <li><span className="icon-man"></span>Expert guidance to build your start-up.</li>
                        <li><span className="icon-bag-dollar"></span>Save time, resource and money!</li>
                        <li><span className="icon-golf2"></span>Create endless business possibilites!</li>
                    </ul>
                    <p className="regular-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna. Quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. </p>
                </div>
                {/* <!--end text box--> */}
                <div className="col-xl-1 col-lg-1 col-md-1 col-sm-12"> </div>
                {/* <!--form for free quote--> */}
                <div className="col-xl-4 col-lg-4 col-md-5 col-sm-12">
                    <div className="customise-form">
                        <form className="email_form" method="post">
                            <div className="form-element-wrapper">
                                <h3>Get a Free Quote</h3>
                                <div className="form-group customised-formgroup"> <span className="icon-user"></span>
                                    <input type="text" name="full_name" className="form-control" placeholder="Name" />
                                </div>
                                <div className="form-group customised-formgroup"> <span className="icon-envelope"></span>
                                    <input type="email" name="email" className="form-control" placeholder="Email" />
                                </div>
                                <div className="form-group customised-formgroup"> <span className="icon-telephone"></span>
                                    <input type="text" name="phone" className="form-control" placeholder="Phone" />
                                </div>
                                {/* <div className="form-group customised-formgroup"> <span className="icon-laptop"></span>
                                    <input type="text" name="website" className="form-control" placeholder="Website" />
                                </div> */}
                                <div className="form-group customised-formgroup"> <span className="icon-bubble"></span>
                                    <textarea name="message" className="form-control" placeholder="Message"></textarea>
                                </div>
                            </div>
                            <div>
                                <button type="submit" className="btn btn-fill full-width">GET A QUOTE<span className="icon-chevron-right"></span></button>
                            </div>
                        </form>
                    </div>
                </div>
                {/* <!--end form for free quote--> */}
            </div>
        </div>
    </section>
  );
};