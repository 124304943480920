import React from "react";
// import { CaseStudies } from "./components/CaseStudies";
import { Clients } from "./components/Clients";
// import { Blog } from "./components/Blog";
import { Portfolio } from "./components/Portfolio";
import { Services } from "./components/Services";
import { Slider } from "./components/Slider";
import { Stats } from "./components/Stats";
import { WhyUs } from "./components/WhyUs";
export const Home = () => {
  return (
    <React.Fragment>
      <Slider />
      <Clients />
      <Services />
      <Portfolio />
      {/* <CaseStudies /> */}
      <WhyUs />
      <div className="clearfix"></div>
      <Stats />
      {/* <Blog /> */}
    </React.Fragment>
  );
};
