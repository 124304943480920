import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from './Layout/Layout';

import { Home } from './pages/Home';
import { About } from './pages/About';
import { Contact } from './pages/Contact';
import { Portfolio } from './pages/Portfolio';
// import { CaseStudy } from './pages/CaseStudy';
// import { CaseStudies } from './pages/CaseStudies';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TermsConditions } from './pages/TermsConditions';


import { Team } from './pages/Team';
// import { Services } from './pages/Services';
import { ServicesWebDevelopment } from './pages/ServicesWebDevelopment';
import { ServicesMobileAppDevelopment } from './pages/ServicesMobileAppDevelopment';
import { ServicesSEO } from './pages/ServicesSEO';
import { Careers } from './pages/Careers';



function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />

          {/* <Route path="case-studies" element={<CaseStudies />} />
          <Route path="case-studies/:slug" exact="true" element={<CaseStudy />} /> */}

          <Route path="/team" element={<Team />} />
          {/* <Route path="/services" element={<Services />} /> */}
          <Route path="/services/web-development" element={<ServicesWebDevelopment />} />
          <Route path="/services/mobile-app-development" element={<ServicesMobileAppDevelopment />} />
          <Route path="/services/seo" element={<ServicesSEO />} />

          <Route path="/careers" element={<Careers />} />
          {/* <Route path="careers" element={<Careers />} /> */}

            {/* <Route path="services/logo-and-branding" element={<LogoAndBranding />} /> */}
            {/* <Route path="services/web-development" element={<WebsiteDevelopment />} /> */}
            {/* <Route path="services/mobile-app-development" element={<MobileAppDevelopment />} /> */}
            {/* <Route path="services/seo" element={<Seo />} /> */}
            {/* <Route path="services/pay-per-click" element={<PayPerClick />} /> */}
            {/* <Route path="services/social-media-marketing" element={<SocialMediaMarketing />} /> */}

          {/* <Route path="blog" element={<Blog />} />
          <Route path="blog/:slug" element={<BlogSingle />} /> */}
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>

      {/* <Routes>
        <Route path="/" element={<Home />} exact="true" />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
      </Routes> */}
    </div>
  );
}

export default App;
