import React from "react";
import { PortfolioItem } from "../helpers/helpers";
export const Portfolio = () => {
  return (
    <div className="text-left">
      {/* <!-- ++++ banner ++++ --> */}
      <section className="banner  o-hidden banner-inner portfolio-banner">
        <div className="container">
          {/* <!--banner text--> */}
          <div className="banner-txt">
            <h1>Portfolio</h1>
            <p className="semi-bold">
              We use strategic approaches to provide our clients with high-end.
              <br /> services that ensure superior customer satisfaction.
            </p>
            <a href="portfolio.html#more-portfolio" className="medium-btn2 btn btn-nofill page-scroll">
              DISCOVER MORE
            </a>{" "}
          </div>
          {/* <!--end banner text--> */}
        </div>
      </section>
      {/* <!-- ++++ end banner ++++ --> */}
      {/* <!-- ++++ Featured Project content ++++ --> */}
      <section className="o-hidden bg-white featured-design-section mobile-app-featured">
        <div className="container">
          {/* <!--section title --> */}
          <h2 className="b-clor">Featured Projects</h2>
          <hr className="dark-line" />
          {/* <!--end section title --> */}
          <div className="row margin-top-40 portfolio-p l-margin portfolio">
            <div className="col-sm-6 col-md-4">
              <div className="grid-item">
                <div className="img_container is-featured">
                  <img src="/images/portfolio/por-fea-project-three.jpg" alt="port_img" className="img-responsive" />
                  <div className="overlay">
                    <a className="btn btn-nofill proDetModal1">Discover</a>
                  </div>
                  {/* <!-- End of .overlay --> */}
                </div>
                {/* <!-- End of .img_container --> */}
                <div className="text-content">
                  <h3>
                    <a className="proDetModal1">
                      College Management System<span>Featured - Website</span>
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="grid-item">
                <div className="img_container is-featured">
                  <img src="/images/portfolio/por-fea-project.jpg" alt="port_img" className="img-responsive" />
                  <div className="overlay">
                    <a className="btn btn-nofill proDetModal2">Discover</a>
                  </div>
                  {/* <!-- End of .overlay --> */}
                </div>
                {/* <!-- End of .img_container --> */}
                <div className="text-content">
                  <h3>
                    <a className="proDetModal2">
                      Google<span>Featured - Logo</span>
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4">
              <div className="grid-item">
                <div className="img_container is-featured">
                  <img src="/images/portfolio/portfolio-img-one.jpg" alt="port_img" className="img-responsive" />
                  <div className="overlay">
                    <a className="btn btn-nofill proDetModal3">Discover</a>
                  </div>
                </div>
                <div className="text-content">
                  <h3>
                    <a className="proDetModal3">
                      Gumtree<span>Featured - eCommerce</span>
                    </a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="more-portfolio" className="portfolio-full portfolio clearfix">
        <div className="container">
          <h2 className="b-clor">Full Portfolio</h2>
          <hr className="dark-line" />
          <div className="grid clearfix row">
            <PortfolioItem name="Trinay Technologies" type="Website" img="portfolio-one.jpg" />
            <PortfolioItem name="Proleap Systems" type="Website" img="portfolio-two.jpg" />
            <PortfolioItem name="Upquilk" type="Website" img="portfolio-three.jpg" />
          </div>
          <div className="text-center port-dms">
            <a href="portfolio.html" className="btn btn-fill full-width">
              Discover more
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};
