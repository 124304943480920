import React from "react";
import { Link } from "react-router-dom";
export const About = () => {
  return (
    <>
      <section className="banner  o-hidden banner-inner about-banner">
        <div className="container">
          <div className="banner-txt">
            <h1>About</h1>
            <p className="semi-bold">
              What makes a company great? That's simple.
              <br /> It's the people who work here.
            </p>
            <Link to="#more-about" className="medium-btn2 btn btn-nofill page-scroll">
              EXPERIENCE US
            </Link>
          </div>
        </div>
      </section>
      <section className="bg-white o-hidden common-form-section contact-form-wrapper more-about" id="more-about">
        <div className="container">
          <h2 className="b-clor">We're Your Partner in Your Success</h2>
          <hr className="dark-line" />

          <div className="row about-content">
            <div className="col-md-5 col-sm-12 pull-right">
              <div className="about-img">
                <img src="/images/about/about-right-img.jpg" alt="about" className="img-responsive" />
              </div>
            </div>
            <div className="col-md-7 col-sm-12 pull-left">
              <div>
                <p className="regular-text">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut
                  laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation
                  ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor
                  in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis
                  at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum.
                </p>
                <p className="regular-text">
                  Nam liber tempor cum soluta nobis eleifend option congue nihil imperdiet doming id quod mazim placerat
                  facer possim assum. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
                  lobortis nisl. Typi non habent claritatem insitam; est usus legentis in iis qui facit eorum
                  claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas
                  est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum.
                </p>
                <p className="regular-text">Mirum est notare quam littera gothica, quam nunc putamus parum claram.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
