export const mobile = '+919618749506';
export const email = 'info@structtechnologies.com';
export const name = 'Struct Technologies Pvt Ltd';
export const socailaMedia = {
  facebook:'',
  instagram:'',
  youtube:'',
  linkedin:'',
  twitter:'',
  pinterest:'',
  google:'',
}