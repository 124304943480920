import React from 'react';
export const TermsConditions = () =>{
  return (<div className="text-left">
  <section className="blog-title">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <h1>Terms & Conditions</h1>
                </div>
            </div>
        </div>
    </section>
    {/* <!-- ++++ Most Bold Title ++++ --> */}
    {/* <!-- ++++ blog content ++++ --> */}
    <section className="bg-white privecy-content ">
        {/* <!--blog content row one--> */}
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                    <p className="regular-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril delenit augue duis dolore te feugait nulla facilisi.</p>
                    <h3>Nam liber tempor cum soluta</h3>
                    <p className="regular-text">Nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Typi non habent claritatem insitam; est usus legentis in iis qui facit eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum.</p>
                    <h3>Mirum est notare quam</h3>
                    <p className="regular-text">Littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>
                    <h3>Nobis eleifend option congue nihil</h3>
                    <p className="regular-text">Imperdiet doming id quod mazim placerat facer possim assum. Typi non habent claritatem insitam; est usus legentis in iis qui facit eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum.</p>
                    <h3>Littera gothica, quam nunc</h3>
                    <p className="regular-text">Putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>
                    <h3>Nam liber tempor cum soluta</h3>
                    <p className="regular-text">Nobis eleifend option congue nihil imperdiet doming id quod mazim placerat facer possim assum. Typi non habent claritatem insitam; est usus legentis in iis qui facit eorum claritatem. Investigationes demonstraverunt lectores legere me lius quod ii legunt saepius. Claritas est etiam processus dynamicus, qui sequitur mutationem consuetudium lectorum.</p>
                    <h3>Mirum est notare quam</h3>
                    <p className="regular-text">Littera gothica, quam nunc putamus parum claram, anteposuerit litterarum formas humanitatis per seacula quarta decima et quinta decima. Eodem modo typi, qui nunc nobis videntur parum clari, fiant sollemnes in futurum.</p>
                </div>
            </div>
        </div>
        {/* <!--end blog content row one--> */}
    </section>
  </div>)
}