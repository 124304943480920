import React from "react";
import { Link } from "react-router-dom";
import { email, mobile } from "./../config";
import { useLocation } from "react-router-dom";

export const Topheader = () => {
  const pathname = useLocation().pathname;
  return (
    <>
      <div className="header-wrapper" style={{ position: "relative" }}>
        <header id="top">
          <div className="container">
            <div className="row">
              <div className="col-xl-7 col-lg-7 col-md-6 col-sm-12  hidden-xs">
                <div id="custom-search-input">
                  <div className="input-group">
                    <span className="input-group-btn">
                      <button className="btn" type="button">
                        <span className="icon-magnifier"></span>
                      </button>
                    </span>
                    <input
                      type="text"
                      className="search-query form-control"
                      placeholder="Search"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12">
                <ul className="pull-right header-right">
                  <li>
                    <a href={`tel:${mobile}`}>
                      <span className="icon-telephone"></span>
                      <span>{mobile}</span>
                    </a>
                  </li>
                  <li>
                    <img
                      src="/images/separator.png"
                      alt="separator"
                      className="img-responsive"
                    />
                  </li>
                  <li>
                    <a href={`mailto:${email}`}>
                      <span className="icon-envelope"></span>
                      <span>{email}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
        {/* <!-- end most top information --> */}
        {/* <!--navigation--> */}
        <nav id="navbar-main" className="navbar main-menu navbar-expand-md">
          <div className="container">
            {/* <!--Brand and toggle get grouped for better mobile display--> */}
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggler collapsed"
                data-bs-toggle="collapse"
                data-bs-target="#navbar-collapse-1"
                aria-expanded="false"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
              <Link className="navbar-brand" to="/">
                <img
                  src="/images/logo.png"
                  alt="Brand"
                  className="img-responsive"
                />
              </Link>
            </div>
            {/* <!--Collect the nav links, and other content for toggling--> */}
            <div className="collapse navbar-collapse" id="navbar-collapse-1">
              <ul className="nav navbar-nav navbar-right">
                <li>
                  <Link to="/" className={pathname === "/" && "active"}>
                    Home
                  </Link>
                </li>
                <li className="dropdown">
                  <Link
                    to="#"
                    data-bs-toggle="dropdown"
                    className={`dropdown-toggle`}
                  >
                    Services
                  </Link>
                  <ul className="dropdown-menu">
                    {/* <li><Link to="services/logo-and-branding"><span className="icon-palette"></span>Logo &amp; Branding</Link></li> */}
                    <li>
                      <Link
                        to="services/web-development"
                        className="b-clor"
                      >
                        <span className="icon-laptop-phone"></span>Website
                        Development
                      </Link>
                    </li>
                    <li>
                      <Link to="services/mobile-app-development">
                        <span className="icon-smartphone-embed"></span>Mobile
                        App Development
                      </Link>
                    </li>
                    <li>
                      <Link to="services/seo">
                        <span className="icon-magnifier"></span>Search Engine
                        Optimization
                      </Link>
                    </li>
                    {/* <li><Link to="services/pay-per-click"><span className="icon-select2"></span>Pay-Per-Click</Link></li> */}
                    {/* <li><Link to="services/social-media-marketing"><span className="icon-share"></span>Social Media Marketing</Link></li> */}
                  </ul>
                </li>
                <li>
                  <Link
                    to="portfolio"
                    className={pathname === "/portfolio" && "active"}
                  >
                    Portfolio
                  </Link>
                </li>
                {/* <li><Link to="case-studies">Case Studies</Link></li> */}
                <li>
                  <Link
                    to="about"
                    className={pathname === "/about" && "active"}
                  >
                    About
                  </Link>
                </li>
                {/* <li className="dropdown"> <Link to="#" data-bs-toggle="dropdown" className="dropdown-toggle">Blog</Link>
                            <ul className="dropdown-menu">
                                <li><Link to="blog">Blog Version 1</Link></li>
                                <li><Link to="blog-version-2" className="b-clor">Blog Version 2</Link></li>
                            </ul>
                        </li> */}
                <li>
                  <Link
                    to="contact"
                    className={pathname === "/contact" && "active"}
                  >
                    Contact
                  </Link>
                </li>
                <li
                  className="btn btn-fill"
                  data-bs-toggle="modal"
                  data-bs-target="#getAQuoteModal"
                >
                  <Link to="#">
                    GET A QUOTE<span className="icon-chevron-right"></span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};
