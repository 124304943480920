import React from 'react'
import { Outlet } from 'react-router-dom'
import { Footer } from './Footer'
import { Topheader } from './Topheader'
export const Layout = () => {
  return (<>
    <Topheader />
    <div>
      <Outlet />
    </div>
    <Footer />
  </>)
}