import React from "react";
import { PortfolioItem } from "../../helpers/helpers";
export const Portfolio = () => {
  return (
    <section className="portfolio clearfix" id="portfolio-home">
      <div className="container">
        {/* <!--section title --> */}
        <h2 className="b-clor">Our Impressive Portfolio</h2>
        <hr className="dark-line" />

        <div className="grid clearfix row">
          <PortfolioItem name="Trinay Technologies" type="Website" img="portfolio-one.jpg" />
          <PortfolioItem name="Proleap Systems" type="Website" img="portfolio-two.jpg" />
          <PortfolioItem name="Upquilk" type="Website" img="portfolio-three.jpg" />
        </div>
        <div className="text-center port-dms">
          {" "}
          <a href="portfolio.html" className="btn btn-fill full-width">
            Discover more
          </a>{" "}
        </div>
      </div>
    </section>
  );
};
